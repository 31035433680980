@import "styles/variables.scss";
@import "styles/mixins.scss";
@import "styles/extends.scss";

.footerMenuContainer {
  width: 100%;
  height: 50%;
  color: $black;

  ul {
    @extend %footerList;

    .menuTextOff {
      @extend %footerLinks;
      border: 1px solid $black;

      a {
        @include anchor-style(none, inherit);
      }
    }
  }
}

.footerMenuContainer {
  ul {
    @extend %footerList;

    .menuTextOn {
      @extend %footerLinks;
      color: $white;
      border: 1px solid $white;

      a {
        @include anchor-style(none, inherit);
      }
    }
  }
}

@media screen and (min-width: $tablet-breakpoint) {
  .footerMenuContainer {
    width: 100vw;
    height: 4vh;
    background-color: $footerColor;
    z-index: 1;
    position: fixed;
    bottom: 0;

    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 0;

      .menuTextOff, .menuTextOn {
        font-family: $primaryFont;
        border: 0;
        height: 0;
        width: fit-content;
        z-index: 1;
        color: $black;
        font-size: 16px;
      }
    }
  }
}
