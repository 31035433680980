@import "styles/extends.scss";
@import "styles/variables.scss";

.pagination {
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style-type: none;
    padding: 10px 12px;
}

.paginationPage {
    //padding: 0.5rem;
    padding: 4px 10px;
    font-size: 2rem;
    cursor: pointer;
    margin: auto;
    &:hover {
        @extend %paginationPageBg;
    }
}

.paginationActive {
    @extend %paginationPageBg;
}

.paginationArrow {
    display: flex;
    align-items: center;
    size: 5rem;
    padding: 4px 10px;
    cursor: pointer;

    &:hover {
        @extend %paginationPageBg;
    }
}

.paginationDisabled {
    opacity: 0.5;
    &:hover {
        background: none;
    }
}

.arrowIcons {
    font-size: 2.5rem;
}