@import "styles/variables.scss";

.dropdownContainer {
  height: 40vh;
  overflow: scroll;
  background-color: rgba(0, 0, 0, 0.72);
  color: white;
  position: absolute;
  top: 100%;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  margin-right: 0px;
    margin-bottom: -17px;

  &::-webkit-scrollbar {
    display: none;
  }

  .dropdownOption {
    padding: 2% 5%;
    &:hover {
      background-color: rgba(255, 255, 255, 0.275);
      cursor: pointer;
    }
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    font-family: $filterFont;
    &:first-child {
      background-color: $defaultFilterColor;
    }
  }
}
