body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 12px;
}

@media screen and (min-width: 480px) {
  html {
    font-size: 16px;
  }
}