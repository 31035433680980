@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap");
// colors

$logoColor: #917329;
$white: #ffffff;
$black: #000000;
$overlayColor: rgba(13, 12, 12, 0.45);
$fontColor: #424242;
$borderColor: rgba(0, 0, 0, 0.5);
$footerColor: rgba(196, 196, 196, 0.68);
$defaultFilterColor: rgba(145, 116, 41, 0.308);
$card_details_color: #56410d;
// fonts

$logoFont: "JustMandrawn";
$primaryFont: "Poppins";
$filterFont: "Raleway";
$descImageFont: "Oswald";

// screens

$mobile-breakpoint: 480px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1024px;

// job card
$font-size-title: 1.2rem;
$font-size-details: 0.85rem;
$font-family-card-company: $primaryFont;
$margin-bottom-card: 12px;
$jobCard-border-color: #cecece;
$jobTitle-border-color: #c7b577;

//pagination
$pagination-button-Bg: rgb(206, 203, 203);
$pagination-Bg: rgb(247, 244, 244);
//breakpoints media query
$mobile-breakpoint: 480px;
$tablet-breakpoint: 768px;
