@import 'styles/variables.scss';

%paginationPageBg {
    background-color: $pagination-button-Bg;
    padding: 4px 10px;
    border-radius: 2rem / 5rem;
}

%footerList {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    margin: 0;
}

%footerLinks {
    list-style: none;
    width: 45%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 5px;
    text-align: center;
}