@import "../../styles/variables.scss";

.formSearchBar {
  display: flex;
  justify-content: center;
  margin-top: 0%;
}

.searchInputBlack {
  width: 80vw;
  margin-bottom: 20px;
  border-style: none;
  border-bottom: 1px solid $black;
  font-size: 1.5rem;
  background: transparent;

  &::placeholder {
    color: $black;
  }
}
.searchInputWhite {
  width: 80vw;
  margin-bottom: 20px;
  border-style: none;
  border-bottom: 1px solid $white;
  font-size: 1.5rem;
  background: transparent;
  color: $white;

  &::placeholder {
    color: $white;
  }
}

.searchInputWhite:focus {
  outline: none;
}
.searchInputBlack:focus {
  outline: none;
}

.searchButtonBlack {
  border-style: none;
  margin-bottom: 20px;
  background: transparent;
  border-bottom: 1px solid $black;
  font-size: 2rem;
  color: $black;
}
.searchButtonWhite {
  border-style: none;
  margin-bottom: 20px;
  background: transparent;
  border-bottom: 1px solid $white;
  font-size: 2rem;
  color: $white;
}

@media screen and (min-width: $tablet-breakpoint) {
  .formSearchBar {
    margin-top: 0;
  }
  .searchInputWhite {
    width: 60%;

    &::placeholder {
      font-size: 1.2rem;
      }
  }
  .searchInputBlack {
    width: 67%;

    &::placeholder {
      font-size: 1.2rem;
    }
  }
}
