@import "../../styles/variables.scss";
.backgroundSliderContainer {
  height: 100%;
  width: 100%;
  .eachSlide {
    height: 100vh;
  }
  .backgroundStyle {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .infoContainer {
    font-size: 0.85rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(127, 255, 212);
    font-family: Oswald;
    bottom: 5%;
    width: fit-content;
    block-size: fit-content;
    left: 0.5%;
    pointer-events: all;

    .authorPage {
      cursor: pointer;
      display: none;
    }
    &:hover {
      .authorPage {
        display: block;
      }
    }
  }
  .switchBackgroundIconContainer {
    display: flex;
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 6;
    margin-right: 50px;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.638);
      color: rgba(250, 250, 250, 0.702);
      margin-right: 4px;
      border-radius: 9%;
      cursor: pointer;
      font-size: 35px;
      width: 30px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.802);
        color: rgba(250, 250, 250, 0.9);
      }
    }
  }
  .noIcon {
    display: none;
  }
}

.toggleButtonContainer {
  position: absolute;
  bottom: 5%;
  right: 1%;
}
@media screen and (min-width: $mobile-breakpoint) {
  .backgroundSliderContainer {
    .eachSlide {
      height: 100vh;
    }
  }
}
