@import "styles/variables.scss";


.jobCard {
  font-family: $primaryFont;
  background-color: $white;
  padding: 1.5rem;
  border: 0.5px solid $jobCard-border-color;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-bottom: $margin-bottom-card;

  &__title {
    font-size: $font-size-title;
    border-bottom: 1px solid $jobTitle-border-color;
    padding-bottom: 1.5rem;
    overflow: hidden;
  }

  &__details {
    font-size: $font-size-details;
    color: $card_details_color;

    h3 {
      padding-bottom: 5px;
      margin: 0;
    }
  
  }
}

